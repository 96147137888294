/*
 * Styles defined here should be limited to overrides of Plone
 * admin UI CSS, which is not really part of the theme, per se.
 */

dl.actionMenu ul {
    list-style: none;
}

dd.actionMenuContent {
    margin-top: 0;
}

#edit-bar-wrapper,
#edit-bar,
#content-views,
#contentActionMenus,
#contentActionMenus dl.actionMenu a,
#contentActionMenus dl.actionMenu.activated dd,
#edit-bar-wrapper #portal-personaltools,
#edit-bar-wrapper #portal-personaltools dd {
    background-color: #2a7504;
    border-color: #2a7504;
}

#edit-bar {
    font-size: 0.8em;
    margin: 0;
    min-height: 2.5em;
    border-radius: 0;
}

#edit-bar-wrapper {
    background-color: #2a7504;
    margin: 0;
    position: relative;
    z-index: 1000;
}

#edit-bar-wrapper * {
    margin-top: 0;
}

#edit-bar-wrapper #portal-personaltools {
    margin-top: 0.25em;
}
#portal-personaltools li {
    margin: 0;
}
#portal-personaltools a {
    color: white !important;
}

#portal-personaltools dd a {
    line-height: 2em;
    padding: 0 0.75em;
}

#portal-personaltools dd a:hover {
    background-color: white;
    color: #2a7504 !important;
}

#portlet-prefs ul {
    list-style-image: none;
    list-style-type: none;
}






